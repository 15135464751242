import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { Chart, registerables } from 'chart.js';
import {
  ASSESSMENT_PLAN_TABLE_COLUMNS,
} from './dashboard.constants';
import { DashboardService } from './dashboard.service';
import { Router } from '@angular/router';
import { ExportService } from '@app/exportservice/export.service';
import { UtilService } from '@app/shared/services/util.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {

  rolesData: {date: string, count: number}[] = [];
  categoriesData: {date: string, count: number}[] = [];
  usersData: {date: string, count: number}[] = [];
  candidatesData: {date: string, count: number}[] = [];
  assessmentsData: {date: string, count: number}[] = [];
  violationsData: {name: string, data: any}[] =[
    { name: 'Violation Count', data: [0] }
  ];
  leadsData: {date: string, count: number}[] = [];
  customersData: {date: string, count: number}[] = [];
  leadCustomerData: {name: string, data: any}[] = [
    { name: 'Leads', data: [0] },
    { name: 'Customers', data: [0] }
  ];
  coursesData: {date: string, count: number}[] = [];
  questionBanksData: {date: string, count: number}[] = [];
  questionsData: {name: string, data: any}[] =[
    { name: 'Current Question Count', data: [0,0,0] },
    { name: 'Previous Question Count', data: [0,0,0] }
  ];
  billsData: {date: string, count: number}[] = [];
  invoicesData: {date: string, count: number}[] = [];
  collectedData: {date: string, count: number}[] = [];
  protected orgId: any;
  userOrgList: any = [];
  user_id: any;
  org_id: any;
  
  isLoading: boolean = false;
  dataSource: object[];

  // Data sources for each table
  orgUpdatesData: object[] = [
  ];
  
  myUpdatesData: object[] = [
  ];  

  // Date Range Options
  dateRanges: string[] = ['Today', '7d', '2w', '1m', 'Custom'];
  selectedRange: string = 'Today';

  // Store the start and end dates for the custom range
  startDate: Date | null = null;
  endDate: Date | null = null;

  // Accordion Sections
  sections = [
    { title: 'Activity Tracker' },
    { title: 'Sales' },
    { title: 'Content' },
    { title: 'Operations' },
    { title: 'Finance' },
    { title: 'Resources' },
  ];

  // Currently Open Section
  openSection: number | null = null;

  // Sample activity data
  activityData = [
    { date: '2024-12-01', activity: 'Login', status: 'Completed' },
    { date: '2024-12-02', activity: 'Logout', status: 'Completed' },
    { date: '2024-12-03', activity: 'Update Profile', status: 'Pending' },
    // More data here...
  ];

   // Tabs and selectedTab tracking
   tabs = ['Org Updates', 'My Updates'];
   selectedTab = 'Org Updates'; // Default to Org Updates tab

  // Chart references to prevent multiple initializations
  charts: { [key: string]: Chart } = {};

  constructor(
    private route: Router,
        private exportService: ExportService,
        private cdr: ChangeDetectorRef,
        private service: DashboardService,
        private util: UtilService,
        private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.orgId = this.util.decrypt(localStorage.getItem('OrgId'));
    this.userOrgList = JSON.parse(localStorage.getItem('userOrgList') || '[]'); 
    // Find the selected organization
    const selectedOrg = this.userOrgList.find(org => org.org_id == this.orgId);

    if (selectedOrg) {
      this.user_id = selectedOrg.user_id;
      this.org_id = selectedOrg.org_id;
    } else {
      this.user_id = null;
      this.org_id = null;
    }
  }
  

  ngAfterViewInit(): void {
  }

  fetchDataBasedOnRange(): void {

      this.getRevisionHistory();
  
      this.getResourcesData();

      this.getOperationsData();

      this.getSalesData();

      this.getContentsData();

      this.getFinancesData();
  }

  
  selectRange(range: string): void {
    this.selectedRange = range;
    if (range !== 'Custom') {
      this.startDate = null;
      this.endDate = null;
      this.fetchDataBasedOnRange();
      this.cdr.detectChanges();
      
    }
  }

   // This function is called whenever the startDate or endDate changes
  onDateChange(): void {
    if (this.startDate && this.endDate) {
      // Both startDate and endDate must be selected before calling the function
      this.fetchDataBasedOnRange();
      this.cdr.detectChanges();
    }
  }

  toggleSection(index: number): void {
    this.openSection = this.openSection === index ? null : index;
    this.fetchDataBasedOnRange();
    this.cdr.detectChanges();
  }

   // Tab Selection Logic
   selectTab(tab: string) {
    this.selectedTab = tab; // Set the active tab by title
    this.getRevisionHistory();
    this.cdr.detectChanges();
  }

  formatDateRange(rangeType, customStartDate = null, customEndDate = null) {
    let startDate, endDate;
    const today = moment().format('YYYY-MM-DD');  // Today's date in YYYY-MM-DD format
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');  // Tomorrow's date in YYYY-MM-DD format

    // End date is tomorrow
    endDate = tomorrow;
    switch (rangeType) {
        case 'Today':
            startDate = today;  // Start date is today
            break;
        case '7d':
            startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');  // 7 days ago
            break;
        case '1m':
            startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'); // Start of the last month
            break;
        case '2w':  // Case for 2 weeks ago
            startDate = moment().subtract(2, 'weeks').format('YYYY-MM-DD');  // 2 weeks ago
            break;
        case 'Custom':
            if (customStartDate && customEndDate) {
                startDate = moment(customStartDate).format('YYYY-MM-DD');
                endDate = moment(customEndDate).format('YYYY-MM-DD');
            } else {
                throw new Error("For custom range, both startDate and endDate must be provided.");
            }
            break;
        default:
            throw new Error("Invalid rangeType. Valid options are: 'today', '7d', '1w', '1m', '2w', 'custom'.");
    }

    return { startDate, endDate };
}


  get displayedColumns() {
    // Dynamically filter the columns based on the selectedTab
    return this.selectedTab === 'My Updates'
      ?ASSESSMENT_PLAN_TABLE_COLUMNS.filter(
          (column) => column.dataKey !== 'changedBy'
        )
      : ASSESSMENT_PLAN_TABLE_COLUMNS;
  }

  getRevisionHistory(){
     // Show loading state
     this.isLoading = true;
  
     let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
     // Fetch revision history data from the service
     const resp = this.service.getRevisionHistory({org_id: this.org_id, user_id: this.user_id, start_date: startDate, end_date: endDate });
     resp.subscribe(
       (data: any) => {
         const response = data['body']['responseBody'];
         if (response) {
           // Process orgRevisionHistory
           this.orgUpdatesData = (response.orgRevisionHistory && response.orgRevisionHistory.length > 0)
           ? response.orgRevisionHistory.map((history, index) => ({
               sno: index + 1, // Serial number
               module: history.table_name,
               action: history.action === 'i' ? 'Insert' : history.action === 'u' ? 'Update' : 'Delete',
               changedBy: history.modified_user_fullname || '-', // If modified_user_id is null, show 'System'
               previousState: history.old_values ? JSON.stringify(history.old_values) : '-',
               currentState: JSON.stringify(history.new_values),
               modifiedDate: new Date(history.action_timestamp).toLocaleDateString() // Format timestamp
           }))
           : [];
 
          // Process userRevisionHistory
          this.myUpdatesData = (response.userRevisionHistory && response.userRevisionHistory.length > 0)
            ? response.userRevisionHistory.map((history, index) => ({
                sno: index + 1, // Serial number
                module: history.table_name,
                action: history.action === 'i' ? 'Insert' : history.action === 'u' ? 'Update' : 'Delete',
                previousState: history.old_values ? JSON.stringify(history.old_values) : '-',
                currentState: JSON.stringify(history.new_values),
                modifiedDate: new Date(history.action_timestamp).toLocaleDateString() // Format timestamp
            }))
            : [];
         this.isLoading = false; // Hide loading state
         this.cdr.detectChanges();
         } else {
           console.warn('No response data available');
           this.orgUpdatesData = [];
           this.myUpdatesData = [];
         }
       },
       (error) => {
         this.isLoading = false; // Hide loading state
         console.error('Error fetching revision history:', error);
         this.orgUpdatesData = [];
         this.myUpdatesData = [];
       }
     );
  }

  getResourcesData() {
      // Show loading state
      this.isLoading = true;
      
      let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
      // Fetch revision history data from the service
      const resp = this.service.getResourcesData({ org_id: this.org_id,  start_date: startDate, end_date: endDate});
    
      resp.subscribe(
        (data: any) => {
          const response = data['body']['responseBody'];
          if (response) {
            // Process roles
            this.usersData = (response.user_counts && response.user_counts.length > 0)
            ? response.user_counts
            : []; 

            // Process categories
            this.rolesData = (response.user_role_counts && response.user_role_counts.length > 0)
            ? response.user_role_counts
            : []; 

            // Process users
            this.categoriesData = (response.user_categories_counts && response.user_categories_counts.length > 0)
            ? response.user_categories_counts
            : []; 
            this.isLoading=false;
            this.cdr.detectChanges();
          } else {
            console.warn('No response data available');
            this.rolesData = [];
            this.categoriesData = [];
            this.rolesData = [];
            this.isLoading=false;
            this.cdr.detectChanges();
          }
        },
        (error) => {
          this.isLoading = false; // Hide loading state
          console.error('Error fetching revision history:', error);
          this.rolesData = [];
          this.categoriesData = [];
          this.rolesData = [];
        }
      );
  }

  getOperationsData() {
    // Show loading state
    this.isLoading = true;
    
    let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
    // Fetch revision history data from the service
    const resp = this.service.getOperationsData({ org_id: this.org_id,  start_date: startDate, end_date: endDate});
  
    resp.subscribe(
      (data: any) => {
        const response = data['body']['responseBody'];
        if (response) {
          // Process candidates
          this.candidatesData = (response.operationsData.candidates_counts && response.operationsData.candidates_counts.length > 0) ? response.operationsData.candidates_counts : [];

          // Process assessments
          this.assessmentsData = (response.operationsData.assessments_counts && response.operationsData.assessments_counts.length > 0) ? response.operationsData.assessments_counts : [];

          // Process assessments
          this.violationsData = (response.violationsData && response.violationsData.violation_count) 
            ? [
              { name: 'Violation Count', data: [response.violationsData.violation_count] },
            ]
          : [
            { name: 'Violation Count', data: [0] },
          ];

          this.isLoading=false;
          this.cdr.detectChanges();
        } else {
          console.warn('No response data available');
          this.candidatesData = [];
          this.assessmentsData = [];
          this.violationsData = [
            { name: 'Violation Count', data: [0] },
          ];
          this.isLoading=false;
          this.cdr.detectChanges();
        }
      },
      (error) => {
        this.isLoading = false; // Hide loading state
        console.error('Error fetching revision history:', error);
        this.candidatesData = [];
        this.assessmentsData = [];
        this.violationsData = [
          { name: 'Violation Count', data: [0] },
        ];
      }
    );
}
getSalesData() {
  // Show loading state
  this.isLoading = true;
  
  let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
  // Fetch revision history data from the service
  const resp = this.service.getSalesData({ org_id: this.org_id,  start_date: startDate, end_date: endDate});

  resp.subscribe(
    (data: any) => {
      const response = data['body']['responseBody'];
      if (response) {
        // Process leads
        this.leadsData = (response.leads_counts && response.leads_counts.length > 0) ? response.leads_counts : [];

        // Process customers
        this.customersData = (response.customers_counts && response.customers_counts.length > 0) ? response.customers_counts : [];

        // Summarize counts for leads and customers
        const totalLeadsCount = this.leadsData && this.leadsData.length > 0 
        ? this.leadsData.reduce((sum, item) => sum + (Number(item.count) || 0), 0) 
        : 0;

        const totalCustomersCount = this.customersData && this.customersData.length > 0 
        ? this.customersData.reduce((sum, item) => sum + (Number(item.count) || 0), 0) 
        : 0;

        // Update the leadCustomerData with the summarized totals
        this.leadCustomerData = [
        { name: 'Leads', data: [totalLeadsCount] },
        { name: 'Customers', data: [totalCustomersCount] }
        ];

        this.isLoading=false;
        this.cdr.detectChanges();
      } else {
        console.warn('No response data available');
        this.leadsData = [];
        this.customersData = [];
        this.leadCustomerData = [
          { name: 'Leads', data: [0] },
          { name: 'Customers', data: [0] }
        ];
        this.isLoading=false;
        this.cdr.detectChanges();
      }
    },
    (error) => {
      this.isLoading = false; // Hide loading state
      console.error('Error fetching revision history:', error);
      this.leadsData = [];
      this.customersData = [];
      this.leadCustomerData = [
        { name: 'Leads', data: [0] },
        { name: 'Customers', data: [0] }
      ];
    }
  );
}

getContentsData() {
  // Show loading state
  this.isLoading = true;
  
  let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
  // Fetch revision history data from the service
  const resp = this.service.getContentsData({ org_id: this.org_id,  start_date: startDate, end_date: endDate});

  resp.subscribe(
    (data: any) => {
      const response = data['body']['responseBody'];
      if (response) {
        // Process courses
        this.coursesData = (response.courses_counts && response.courses_counts.length > 0) ? response.courses_counts : [];

        // Process question_banks
        this.questionBanksData = (response.question_banks_counts && response.question_banks_counts.length > 0) ? response.question_banks_counts : [];

        // Process question counts
        this.questionsData = (response.question_counts && response.question_counts.length > 0) 
          ? [
              { name: 'Current Question Count', data: response.question_counts },
              { name: 'Previous Question Count', data: [...this.questionsData[0].data] }
            ]
          : [
            { name: 'Current Question Count', data: [0,0,0] },
            { name: 'Previous Question Count', data: [...this.questionsData[0].data] }
          ];

        this.isLoading=false;
        this.cdr.detectChanges();
      } else {
        console.warn('No response data available');
        this.coursesData = [];
        this.questionBanksData = [];
        this.questionsData = [
          { name: 'Current Question Count', data: [0,0,0] },
          { name: 'Previous Question Count', data: [0,0,0] }
        ];
        this.isLoading=false;
        this.cdr.detectChanges();
      }
    },
    (error) => {
      this.isLoading = false; // Hide loading state
      console.error('Error fetching revision history:', error);
      this.coursesData = [];
        this.questionBanksData = [];
        this.questionsData = [
          { name: 'Current Question Count', data: [0,0,0] },
          { name: 'Previous Question Count', data: [0,0,0] }
        ];
    }
  );
}

getFinancesData() {
  // Show loading state
  this.isLoading = true;
  
  let {startDate, endDate} = this.formatDateRange(this.selectedRange, this.startDate, this.endDate);
  // Fetch revision history data from the service
  const resp = this.service.getFinancesData({ org_id: this.org_id,  start_date: startDate, end_date: endDate});

  resp.subscribe(
    (data: any) => {
      const response = data['body']['responseBody'];
      if (response) {
       // Process bills
        this.billsData = (response.bills_count && response.bills_count.length > 0) ? response.bills_count : [];

        // Process invoice
        this.invoicesData = (response.invoices_count && response.invoices_count.length > 0) ? response.invoices_count : [];

        // Process collected
        this.collectedData = (response.receipts_count && response.receipts_count.length > 0) ? response.receipts_count : [];

        this.isLoading=false;
        this.cdr.detectChanges();
      } else {
        console.warn('No response data available');
        this.billsData = [];
        this.invoicesData = [];
        this.collectedData = [];
        this.isLoading=false;
        this.cdr.detectChanges();
      }
    },
    (error) => {
      this.isLoading = false; // Hide loading state
      console.error('Error fetching revision history:', error);
      this.billsData = [];
      this.invoicesData = [];
      this.collectedData = [];
    }
  );
}


}
