import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.css']
})
export class ViewTicketComponent implements OnInit {
  ticket = {
    id: 22,
    status: 'Open',
    priority: 'High',
    description: 'description',
    detail: 'checking fresh desk',
    comments: [
      { author: 'A', text: 'working on this', timestamp: '2024-12-18 02:37 PM' },
    ],
  };

  newComment = '';

  constructor() {}

  ngOnInit(): void {}

  addComment(): void {
    if (this.newComment.trim()) {
      this.ticket.comments.push({
        author: 'You',
        text: this.newComment,
        timestamp: new Date().toLocaleString(),
      });
      this.newComment = '';
    }
  }

  closeTicket(): void {
    console.log('Ticket closed!');
    // Add logic to close the ticket
  }
}
