import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-newbarchart',
  templateUrl: './newbarchart.component.html',
  styleUrls: ['./newbarchart.component.css']
})
export class NewbarchartComponent implements OnChanges {
  @Input() chartTitle: string = 'Default Chart Title'; // Input for dynamic title
  @Input() chartSeries: any[] = []; // Input for dynamic series data
  @Input() chartCategories: string[] = []; // Input for dynamic x-axis categories

  chartOptions: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartCategories || changes.chartSeries || changes.chartTitle) {

      this.chartOptions = {
        series: this.chartSeries,
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: this.chartTitle,
          align: 'center'
        },
        xaxis: {
          categories: this.chartCategories
        },
        yaxis: {
          title: {
            text: undefined
          }
        },
        colors: ['#FF4560', '#00E396'], // Red for "Previous", Green for "Current"
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // Alternates background colors
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        plotOptions: {
          bar: {
            horizontal: true, // Horizontal bars
            barHeight: '40%',
            endingShape: 'flat' // Flat bars to match the style in the image
          }
        },
        legend: {
          position: 'top',
          markers: {
            shape: 'circle'
          }
        }
      };

      // Manually trigger change detection
      this.cdr.detectChanges();
    }
  }
}
