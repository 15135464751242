import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/authentication.guard';

import { DashboardComponent } from '@app/dashboard/dashboard.component';

import { FileviewerComponent } from '@app/fileviewer/fileviewer.component';

import { TutorialsComponent } from '@app/tutorials/tutorials.component';

import { SettingsComponent } from './settings/settings.component';
import { NotificationsettingsComponent } from './settings/notificationsettings/notificationsettings.component';
import { SearchComponent } from './search/search.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { TicketComponent } from './ticket/ticket.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';

const routes: Routes = [
  {
    path: 'account',
    children: [
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change Password',
        },

      },

      {
        path: '',
        data: {
          showHeader: false, // Controlling Header for a Route
          showFooter: false,
        },
      
        loadChildren: () =>
          import('@app/account/account.module').then((m) => m.AccountModule),
      },
    ],
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
    title: 'Certiplate - Admin',
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    data: {
      breadcrumb: 'Home',
    },
    title: 'Certiplate - Dashboard',
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'Setup',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/setup/setup.module').then((m) => m.SetupModule),
      },
    ],
    title: 'Certiplate - Setup',
  },
  {
    path: 'Finance',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/finance/finance.module').then((m) => m.FinanceModule),
      },
    ],
    title: 'Certiplate - Finance',
  },
  {
    path: 'Sales',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/sales/sales.module').then((m) => m.SalesModule),
      },
    ],
    title: 'Certiplate - Sales',
  },
  {
    path: 'Content',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/content/content.module').then((m) => m.ContentModule),
      },
    ],
    title: 'Certiplate - Content',
  },
  {
    path: 'AttachmentFile',
    component: FileviewerComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'AttachmentFile/:id',
    component: FileviewerComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'Operations',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/operation/operation.module').then(
            (m) => m.OperationModule
          ),
      },
    ],
    title: 'Certiplate - Operations',
  },
  {
    path: 'Resources',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/resources/resources.module').then(
            (m) => m.ResourcesModule
          ),
      },
    ],
    title: 'Certiplate - Resources',
  },
  // {
  //   path: 'Logistics',
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('@app/logistics/logistics.module').then(
  //           (m) => m.LogisticsModule
  //         ),
  //     },
  //   ],
  //   title: 'Certiplate - Logistics',
  // },
  {
    path: 'Tutorials',
    component: TutorialsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
      {
        path: 'notification-settings',
        component: NotificationsettingsComponent,
        data: {
          title: 'Notification Settings',
        },
      },
    ],
    title: 'Certiplate - Settings',
  },
  {
    path: 'search',
    children: [
      {
        path: '',
        component: SearchComponent,
        data: {
          title: 'Search Results',
        },
      },
    ],
    title: 'Certiplate - Search',
  },
  {
    path: 'ticket',
    component: TicketComponent,
    data: {
      title: 'Ticket',
      breadcrumb: [
        {
          label: 'home', // Label for the breadcrumb
          url: '/Dashboard'   // URL to navigate to when clicked
        }
      ],
    },
  },
  {
    path: 'view-ticket',
    component: ViewTicketComponent,
    data: {
      title: 'Ticket-detail',
      breadcrumb: [
        {
          label: 'home', // Label for the breadcrumb
          url: '/Dashboard'   // URL to navigate to when clicked
        }
      ],
    },
  },
  
  { path: '**', redirectTo: 'account', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      // Restore the last scroll position
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
