// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /*-------------------------------------------------------------------------------------------------*/
  DATE_FORMAT: 'DD-MMM-YYYY',
  LOCALE: 'en-IN',

  /*-------------------------------------------------------------------------------------------------*/
  DEFAULT_VISIBILITY_IDS: [8, 21],
  SUB_MENU_IDS: [19],
  EDITOR_API_KEY: 'rcs2ne9ddapicjwo91rxce0hzf4jmzfgb4rihlvodi3diojx',
  FRESHDESK_API_KEY: 'N8pffHlWxj0X8QgCbj8n',
   FEEDBACK_FORM:'https://navriti.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&submitThanks=++++++++&searchArea=no'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
