<div class="ticket-details">
    <!-- Ticket Information -->
    <div class="ticket-info">
      <p><strong>Ticket Id:</strong> {{ ticket.id }}</p>
      <p><strong>Status:</strong> <span class="status">{{ ticket.status }}</span></p>
      <p><strong>Priority:</strong> <span class="priority">{{ ticket.priority }}</span></p>
    </div>
  
    <!-- Ticket Description -->
    <textarea readonly>{{ ticket.detail }}</textarea>
    <textarea readonly>{{ ticket.description }}</textarea>
  
    <!-- Comments Section -->
    <h3>Comments</h3>
    <div class="comments">
      <!-- Comment Input -->
      <div class="add-comment">
        <input [(ngModel)]="newComment" type="text" placeholder="Add comment" />
        <button (click)="addComment()" class="send-btn">Send</button>
      </div>
  
      <!-- Existing Comments -->
      <div *ngFor="let comment of ticket.comments" class="comment">
        <div class="comment-avatar">{{ comment.author.charAt(0) }}</div>
        <div class="comment-text">{{ comment.text }}</div>
      </div>
    </div>
  
    <!-- Date and Time -->
    <p class="timestamp">{{ ticket.comments[ticket.comments.length - 1]?.timestamp }}</p>
  </div>
  