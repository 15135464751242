export const ASSESSMENT_PLAN_TABLE_COLUMNS = [
    {
      name: 'S.No.',
      dataKey: 'sno',
      position: 'left',
      isSortable: true,
      type: '',
    },
    {
      name: 'Module',
      dataKey: 'module',
      position: 'left',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Action',
      dataKey: 'action',
      position: 'left',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Changed by',
      dataKey: 'changedBy',
      position: 'left',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Previous State',
      dataKey: 'previousState',
      position: 'left',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Current State',
      dataKey: 'currentState',
      position: 'left',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Modified Date',
      dataKey: 'modifiedDate',
      position: 'left',
      type: 'date',
      isSortable: true
    },
  ];
  