import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {environment} from "../environments/environment";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const responseType = request.params.get('responseType');
    const type = request.urlWithParams.search(/type=formdata/) > -1;

    // Conditionally apply Basic Auth for Freshdesk API requests
    if (request.url.includes('freshdesk.com')) {
      // Use Basic Authentication for Freshdesk API requests
      request = this.addBasicAuthHeader(request);
    } else {
      // Apply Bearer token or other headers for other requests
      request = this.addBearerAuthHeader(request);
      request = this.handleRequestMethod(request, type);
    }

    // Handle POST, PUT or other request methods
   

    return next.handle(request).pipe(
      retry(1),
      catchError((returnedError) => {
        return this.handleServerSideError(returnedError);
      })
    );
  }

  // Method to add Basic Authentication header
  private addBasicAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const apiKey = environment.FRESHDESK_API_KEY;
    const authHeader = apiKey ? `Basic ${btoa(apiKey + ':X')}` : '';
    console.log(authHeader);
    return request.clone({
      setHeaders: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
    });
  }

  // Method to add Bearer Authentication header
  private addBearerAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('JwtToken');
    const authHeader = token ? `Bearer ${token}` : '';
    return request.clone({
      setHeaders: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
    });
  }

  // Method to handle POST/PUT requests and add body or headers
  private handleRequestMethod(
    request: HttpRequest<any>,
    isFormData: boolean
  ): HttpRequest<any> {
    if (request.method === 'POST' || request.method === 'PUT') {
      if (isFormData) {
        return request.clone({
          setHeaders: {
            'Content-type': 'multipart/form-data',
          },
          body: request.body,
        });
      } else {
        return request.clone({
          body: {
            ...request.body,
            requestHead: {
              appName: 'Certiplate UI',
              platform: 'web',
            },
          },
        });
      }
    }
    return request;
  }

  // Method to handle error responses
  private handleServerSideError(returnedError: any): Observable<any> {
    let errorMessage: String;
    if (returnedError.error instanceof ErrorEvent) {
      errorMessage = `Error: ${returnedError.error.message}`;
    } else if (returnedError instanceof HttpErrorResponse) {
      switch (returnedError.status) {
        case 401:
          errorMessage =
            'Unauthorized Access, Logged out. Please Re-login Again';
          this.clearStorage();
          break;
        case 0:
          errorMessage =
            'Backend APIs are not responding, Please try again later';
          break;
        default:
          errorMessage = `Error Status ${returnedError.status}: ${returnedError?.error?.responseHead?.statusCode} - ${returnedError?.error?.responseHead?.statusDesc}`;
      }
    }
    return throwError(errorMessage);
  }

  // Method to clear storage on logout
  private clearStorage() {
    localStorage.removeItem('JwtToken');
    localStorage.removeItem('FRESHDESK_API_KEY');
  }
}
