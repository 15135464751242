import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private api: ApiService,
    private util: UtilService,
    private http: HttpClient
  ) {}

  getRevisionHistory(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.REVISON_HISTORY, postData );
  }

  getResourcesData(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.RESOURCES_DATA, postData );
  }

  getOperationsData(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.OPERATIONS_DATA, postData );
  }

  getSalesData(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.SALES_DATA, postData );
  }
  getContentsData(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.CONTENTS_DATA, postData );
  }
  getFinancesData(data: any) {
    const postData: APIRequest = {
              requestBody: {
                ...data,
              }
            };
    return this.api.post(URL.ADMIN.FINANCES_DATA, postData );
  }

}
