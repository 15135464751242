import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { Actions, TableColumn } from '@app/shared/table/TableColumn';
import { MatPaginator } from '@angular/material/paginator';
import { UtilService } from '@app/shared/services/util.service';
import {environment} from "../../environments/environment"
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Router } from "@angular/router";
@Component({
  selector: 'app-table-view',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnInit, AfterViewInit {
  // Loading state
  isLoading: boolean = false;
  isCreatingTicket: boolean = false; // Controls the modal display
  feedbackFormSRC: SafeResourceUrl;
  ticket = {
    subject: '',
    description: '',
    email: '',
    priority: 1,
    status: 1,
    cc_emails: ''
  };
  rowActionList : Array<Actions> = [
    {
       iconName: 'eye',
      iconTooltip : 'View-Ticket',
      name: 'view_ticket'
    }
  ];  
  // Open the create ticket modal
  openCreateTicketModal(): void {
    this.isCreatingTicket = true;
    console.log(this.feedbackFormSRC)
  }

  // Close the create ticket modal
  closeCreateTicketModal(): void {
    this.isCreatingTicket = false;
  }
  submitTicketForm()
  {

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Table Data Source and Sort
  @ViewChild(MatSort) sort: MatSort;
  tableDataSource = new MatTableDataSource<any>();

  // Table Columns definition (initialized here but will be assigned in ngOnInit)
  tableColumns: TableColumn[];

  // Table Data (mock data for now)
  allCustomerCountsData = [
    {
      id: 1,
      summary: 'Login issue',
      priority: 'High',
      status: 'Open',
      createdDate: '2024-12-18',
    },
    {
      id: 2,
      summary: 'Payment gateway error',
      priority: 'Medium',
      status: 'In Progress',
      createdDate: '2024-12-17',
    },
    {
      id: 3,
      summary: 'UI alignment bug',
      priority: 'Low',
      status: 'Closed',
      createdDate: '2024-12-16',
    },
  ];

  constructor(private cdr: ChangeDetectorRef,private sanitizer: DomSanitizer, private http: HttpClient,  private route: Router) {
    this.feedbackFormSRC = this.sanitizer.bypassSecurityTrustResourceUrl(environment.FEEDBACK_FORM);
  }
  createTicket(): void {
    console.log('Starting ticket creation process.');
  
    // Step 1: Get user email from localStorage
    const userEmail = localStorage.getItem('useremail');
    console.log('User email from localStorage:', userEmail);
    if (!userEmail) {
      alert('User email not found in localStorage!');
      return;
    }
  
    // Step 2: Map priority and status values
    const priorityMap: Record<string, number> = {
      Low: 1,
      Medium: 2,
      High: 3,
      Urgent: 4,
    };
  
    const statusMap: Record<string, number> = {
      Open: 2,
      Pending: 3,
      Resolved: 4,
      Closed: 5,
    };
  
    const mappedPriority =
      priorityMap[String(this.ticket.priority)] || 1; // Convert to string to avoid type mismatch
    const mappedStatus = statusMap[String(this.ticket.status)] || 2;
  
    // Step 3: Prepare the ticket payload
    const ticketPayload = {
      description: this.ticket.description || 'Default description',
      subject: this.ticket.subject || 'Default subject',
      priority: mappedPriority,
      status: mappedStatus,
      email: userEmail,
    };
    console.log('Mapped Ticket payload:', ticketPayload);
  
    // Step 4: Freshdesk API URL
    const apiUrl = 'https://navriti.freshdesk.com/api/v2/tickets';
    console.log('Freshdesk API URL:', apiUrl);
  
    // Step 5: Prepare HTTP headers with API Key
   
  
  
  
    // Step 6: Send the POST request
    console.log('Sending POST request to Freshdesk API...');
    this.http.post(apiUrl, ticketPayload).subscribe(
      (response) => {
        console.log('API Response:', response);
        this.closeCreateTicketModal(); // Close the modal after success
      },
      (error) => {
        console.error('API Error:', error);
      
      }
    );
    this.fetchTickets();
  }
  
  
  
  // Other methods..


  ngOnInit(): void {
    // Table Columns definition
    this.tableColumns = [
      {
        name: 'id',
        dataKey: 'id',
        type: 'sequence',
        position: 'left',
      },
      {
        name: 'summary',
        dataKey: 'summary',
        type: 'text',
        position: 'left',
        isSortable: true,
      },
      {
        name: 'priority',
        dataKey: 'priority',
        type: 'text',
        position: 'left',
        isSortable: true,
      
      },
      {
        name: 'status',
        dataKey: 'status',
        type: 'alias',
        position: 'left',
        isSortable: true,
       
      },
      {
        name: 'createdDate',
        dataKey: 'createdDate',
        type: 'date',
        position: 'right',
       isFilterable: true
      },
      {
        name: 'Actions', // Added for row actions
        dataKey: 'actions',
        type: 'actions',
        position: 'right',
        rowActions: this.rowActionList
      }
    ];

    // Populate the table data source with initial data
    this.fetchTickets();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    // Set paginator and sort for table after the view is initialized
    
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }
  // Table Action Emitter
  tableAction(event: any): void {
    console.log('Table Action Triggered:', event);
    if (event.action === 'edit') {
      this.editRow(event.row);
    } else if (event.action === 'delete') {
      this.deleteRow(event.row);
    }
  }

  // Popup Change Status
  popupChangeStatus(event: any): void {
    console.log('Popup Change Status:', event);
  }

  // Count Action Button
  countAction(event: any): void {
    console.log('Count Action Triggered:', event);
  }

  // Row Action
  rowAction(event: any): void {
    console.log('Row Action Triggered:', event);
  
    switch (event.rowName) {
      case 'view_ticket':
        console.log('Navigating to /view-ticket with ticket data:', event.row);
        this.route.navigate(['/view-ticket'], {
          state: { ticketData: event.row }, // Passing ticket data via state
        });
        break;
  
      default:
        console.log('Unknown action:', event);
    }
  }
  
  // Edit Row
  editRow(row: any): void {
    alert(`Editing Row with ID: ${row.id}`);
  }

  // Delete Row
  deleteRow(row: any): void {
    const confirmDelete = confirm(`Are you sure you want to delete ID: ${row.id}?`);
    if (confirmDelete) {
      this.allCustomerCountsData = this.allCustomerCountsData.filter(
        (item) => item.id !== row.id
      );
      this.tableDataSource.data = this.allCustomerCountsData;
      console.log(`Row with ID: ${row.id} deleted`);

      // Trigger change detection to update the table
      this.cdr.detectChanges();
    }
  }
  fetchTickets(): void {
    const userEmail = localStorage.getItem('useremail');
    if (!userEmail) {
      alert('User email not found in localStorage!');
      return;
    }

    this.isLoading = true; // Show loading indicator
    const apiUrl = `https://navriti.freshdesk.com/api/v2/tickets?email=${[userEmail]}`;

    this.http.get(apiUrl).subscribe(
      (response: any[]) => {
        // Filter tickets by user email
        const userTickets = response;
        this.tableDataSource.data = userTickets.map(ticket => ({
          id: ticket.id,
          summary: ticket.subject,
          priority: this.mapPriority(ticket.priority),
          status: this.mapStatus(ticket.status),
          createdDate: ticket.created_at,
        }));
        console.log('Fetched Tickets:', userTickets);
        this.isLoading = false; // Hide loading indicator
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching tickets:', error);
        alert('Failed to fetch tickets. Please try again.');
        this.isLoading = false; // Hide loading indicator
      }
    );
  }
  mapPriority(priority: number): string {
    const priorityMap = {
      1: 'Low',
      2: 'Medium',
      3: 'High',
      4: 'Urgent',
    };
    return priorityMap[priority] || 'Unknown';
  }

  mapStatus(status: number): string {
    const statusMap = {
      2: 'Open',
      3: 'Pending',
      4: 'Resolved',
      5: 'Closed',
    };
    return statusMap[status] || 'Unknown';
  }

}
