<div class="chart-wrapper">
  <div class="chart-container">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [colors]="chartOptions.colors"
      [dataLabels]="chartOptions.dataLabels"
      [grid]="chartOptions.grid"
      [stroke]="chartOptions.stroke"
      [plotOptions]="chartOptions.plotOptions"
      [legend]="chartOptions.legend"
      [title]="chartOptions.title"
    ></apx-chart>
  </div>
</div>

  