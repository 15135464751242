import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EventBusService } from '@app-shared/services/event-bus.service';
import { UtilService } from '@app-shared/services/util.service';
import { AccountService } from '@app/account/account.service';
import { AdminService } from '@app/admin/admin.service';
import { AppNotificationsService } from '@app/shared/services/app-notifications.service';
import { GetService } from '@app/shared/table/get.service';
import { GetAllService } from '@app/shared/table/getAll.service';
import { SocketService } from '@app/socket.service';
import { get, has } from 'lodash';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TicketComponent } from '@app/ticket/ticket.component';

const USER_ROLE_LIST: any = {
  SA: 'Super Admin',
  OA: 'Organization Admin',
  OU: 'Organization User',
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected SUPER_ADMIN = 'Super Admin';
  username: any;
  useremail: string;
  menus: any[];
  title: string = 'Dashboard';
  nameTitle: string = '';
  nameTitleValue: string = '';
  hideHeader: boolean = false;
  dividerNotRequired: boolean = true;
  isAutoMenuON: boolean = false;
  isAdmin: boolean = false;
  protected sysAccess: any;
  userOrgList: any = [];
  selectedUserOrg: any;
  loginUserRole: string = '';
  isOrgAdmin: boolean = false;
  eventBusSub?: Subscription;
  showHelpDesk: boolean = false;
  showSettings: boolean = false;
  notificationCount = 0;
  developerUrl: any;
  searchInput: string = '';
  show = false;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @ViewChild('headerMenuList', { static: false, read: ElementRef })
  headerMenuList!: ElementRef<HTMLDivElement>;
  @ViewChild('headerMenuItems') headerMenuItems: ElementRef;
  listOfVisibleMenus: any[];
  listOfNonVisibleMenus: any[];
  courseFrameworkList = [];
  protected orgID: any;
  orgLogo: string;
  isOrgSuperAdmin: boolean;
  isOpen = false;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private accSrvc: AccountService,
    private util: UtilService,
    private eventBusService: EventBusService,
    private getService: GetService,
    private getAllService: GetAllService,
    private notificationsService: AppNotificationsService,
    private socketService: SocketService,
    private adminSrvc: AdminService
  ) {}

  ngOnInit(): void {
    if (this.accSrvc.jwt) {
      this.util.setTokenExpiryPopup(this.accSrvc.jwt);
    }
    this.buildPageTitle();
    this.username = localStorage.getItem('username');
    this.useremail = localStorage.getItem('useremail');
    this.selectedUserOrg = this.accSrvc.getUserOrgId();
    this.sysAccess = JSON.parse(
      this.util.decrypt(localStorage.getItem('sys_access'))
    );
    this.menus = JSON.parse(
      this.util.decrypt(localStorage.getItem('menu_access'))
    );
    

    const OPERATIONS_MENU_ORDER = {
      'project': 1,
      'candidates': 2,
      'assessments': 3,
      'analysis': 4
    };
    
    // Filter out Assets from Resources and duplicate Evaluate from Operations
    this.menus = this.menus.map((menu: any) => {
      if (menu.menu_name.toLowerCase() === 'resources') {
        return {
          ...menu,
          pages: menu.pages.filter((page: any) => page.menu_name.toLowerCase() !== 'assets')
        };
      }
      if (menu.menu_name.toLowerCase() === 'operations') {
        return {
          ...menu,
          // Sort the operations menu items
          pages: menu.pages
            .sort((a, b) => {
              const orderA = OPERATIONS_MENU_ORDER[a.menu_name.toLowerCase()] || 999;
              const orderB = OPERATIONS_MENU_ORDER[b.menu_name.toLowerCase()] || 999;
              return orderA - orderB;
            })
            .map((page: any) => {
               // Update 'Project' to 'Projects'
              if (page.menu_name.toLowerCase() === 'project') {
                return {
                  ...page,
                  menu_name: 'Projects'
                };
              }
              if (page.menu_name.toLowerCase() === 'assessments') {
                const getMenuWeight = (menuName: string): number => {
                  const name = menuName.toLowerCase();
                  switch(name) {
                    case 'plan': return 1;
                    case 'conduct viva': return 2;
                    case 'invigilate': return 3;
                    case 'monitor': return 4;
                    case 'evaluate': return 5;
                    default: return 999;
                  }
                };
    
                // Find all evaluate items and keep only the last one
                const evaluateItems = page.pages.filter(item => 
                  item.menu_name.toLowerCase() === 'evaluate'
                );
                const lastEvaluate = evaluateItems[evaluateItems.length - 1];
    
                return {
                  ...page,
                  pages: page.pages
                    .filter(subPage => {
                      if (subPage.menu_name.toLowerCase() === 'execute') return false;
                      if (subPage.menu_name.toLowerCase() === 'evaluate') {
                        return subPage === lastEvaluate;
                      }
                      return true;
                    })
                    .sort((a, b) => 
                      getMenuWeight(a.menu_name.toLowerCase()) - getMenuWeight(b.menu_name.toLowerCase())
                    )
                };
              }
              return page;
            })
        };
      }
      return menu;
    });

    this.orgID = this.util.decrypt(localStorage.getItem('OrgId'));
    this.isAutoMenuON = localStorage.getItem('isautopopulate') === 'on';
    const ur = this.util.decrypt(localStorage.getItem('ur'));
    this.isOrgSuperAdmin = JSON.parse(localStorage.getItem('isOrgSuperAdmin'));
    this.isAdmin = ur === 'SA';
    this.userOrgList = JSON.parse(localStorage.getItem('userOrgList'));
    this.loginUserRole = localStorage.getItem('role');
    if (this.loginUserRole.toLowerCase() === 'full access') {
      this.loginUserRole = 'Super Admin';
    }
    const superUserId = localStorage.getItem('superUserId');
    if (superUserId && parseInt(superUserId, 10) > 1) {
      this.isOrgAdmin = true;
    }
    this.eventBusSub = this.eventBusService.on('logout', () => {
      localStorage.clear();
      this.route.navigate(['/']);
    });
    this.socketService.onNewNotification().subscribe((data: any) => {
      this.util.openNotificationDialog(
        data.title,
        data.message,
        'toast-custom'
      );
      this.notificationCount++;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
    this.developerUrl = `${this.util.getApiUrl()}/docs/?token=${
      this.accSrvc.jwt
    }`;
    this.getCourseFrameworkList();
    if (!this.isAdmin) {
      this.adminSrvc
        .getOrgDetails(parseInt(this.orgID))
        .subscribe((data: any) => {
          const response = data.body.responseBody;
          this.orgLogo = response.logo_file_name;
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  ngAfterViewInit() {
    const elementContainsListOfMenus = this.headerMenuList.nativeElement;
     // Calls when the element size changes
    const resizeObserver = new ResizeObserver(() => {
      this.showMenuItemsBasedOnParentWidth();
       // Force Angular to detect changes and update the view immediately
      this.changeDetectorRef.detectChanges();
    });
    resizeObserver.observe(elementContainsListOfMenus);
    this.showMenuItemsBasedOnParentWidth();
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  showMenuItemsBasedOnParentWidth() {
    let numberOfMenusShouldVisible: number;
    const width = this.headerMenuList.nativeElement.clientWidth;

    if (width <= 220) {
      numberOfMenusShouldVisible = 0;
    } else if (width > 220 && width <= 305) {
      numberOfMenusShouldVisible = 1;
    } else if (width > 305 && width <= 430) {
      numberOfMenusShouldVisible = 2;
    } else if (width > 430 && width <= 560) {
      numberOfMenusShouldVisible = 3;
    } else if (width > 560 && width <= 710) {
      numberOfMenusShouldVisible = 4;
    } else if (width > 710 && width <= 820) {
      numberOfMenusShouldVisible = 5;
    } else {
      numberOfMenusShouldVisible = 6;
    }

    this.listOfVisibleMenus = this.menus.slice(0, numberOfMenusShouldVisible);
    this.listOfNonVisibleMenus = this.menus.slice(numberOfMenusShouldVisible);

   
  }

  /**
   * Generating Headers Dynamically based on the Data Property / Router Params
   */
  buildPageTitle() {
    this.route.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((event) => {
        if (!!event.data && has(event.data, '_value.title')) {
          this.title = get(event.data, '_value.title', '');
        } else {
          this.title = get(event, 'routeConfig.path');
        }
        if (!!event.data) {
          this.dividerNotRequired = get(
            event.data,
            '_value.dividerNotRequired',
            true
          );
          this.nameTitle = get(event.data, '_value.nameTitle', '');
          this.nameTitleValue = get(event.params, 'value.name', '');
          this.hideHeader = get(event.data, '_value.hideHeader', false);
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.eventBusSub) {
      this.eventBusSub.unsubscribe();
    }
  }

  logout() {
    let resp = this.accSrvc.logout();
    resp.subscribe(
      (data: any) => {
        const response = data.body;
        if (response.responseHead.statusCode === '0000') {
          localStorage.clear();
          this.route.navigate(['/']);
        }
      },
      (err: any) => {
        if (
          err.responseHead &&
          !['9999', '9998'].includes(err.responseHead.statusCode)
        ) {
        } else {
          localStorage.clear();
          this.route.navigate(['/']);
        }
      }
    );
  }

  ShowDropdown() {
    var dropdown = document.getElementById('dropdown');
    if (dropdown.style.display !== 'block') {
      dropdown.style.display = 'block';
    } else {
      dropdown.style.display = 'none';
    }
    document.querySelector('body').addEventListener('click', (e: any) => {
      if (
        !e.target.className.includes('form-control org-list') &&
        e.target.className !== 'initials' &&
        e.target.className !== 'mat-button-wrapper' &&
        dropdown.style.display === 'block'
      ) {
        dropdown.style.display = 'none';
      }
    });
  }

  loginWithOrg(data: any) {
    const postData = {
      ...data,
    };
    let resp = this.accSrvc.loginWithOrg(postData);
    resp.subscribe(
      (data: any) => {
        const response = data.body.responseBody;
        localStorage.setItem('JwtToken', response.jwtToken);
        this.util.setTokenExpiryPopup(response.jwtToken);
        localStorage.setItem(
          'sys_access',
          this.util.encrypt(JSON.stringify(response.perm))
        );
        if (response.course_framework_id) {
          const postData = {
            courseFrameworkIds: response.course_framework_id,
          };
          this.getService.get('courseFrameworkLabels', postData).subscribe(
            (data: any) => {
              const frameworkLabelsresponse = data.body.responseBody;
              localStorage.setItem(
                'course_framework_labels',
                this.util.encrypt(JSON.stringify(frameworkLabelsresponse))
              );
            },
            (err: any) => {
              this.util.errorNotification(err);
              this.changeDetectorRef.markForCheck();
            }
          );
        }
        if (response.super_user_id) {
          localStorage.setItem('superUserId', response.super_user_id);
        }
        localStorage.setItem('UserId', response.user_id);
        localStorage.setItem('role', response.role);
        localStorage.setItem(
          'OrgId',
          this.util.encrypt(`${response.organization_id}`)
        );
        localStorage.setItem('username', response.full_name);
        localStorage.setItem('useremail', response.username);
        localStorage.setItem('isautopopulate', 'on');
        localStorage.setItem('isOrgSuperAdmin', response.spoc_is_org_admin);
        if (response.spoc_email) {
          const userRole = response.organization_id === -1 ? 'SA' : 'OA';
          localStorage.setItem('ur', this.util.encrypt(userRole));
        } else {
          localStorage.setItem('ur', this.util.encrypt('OU'));
        }
        this.accSrvc.getMenus().subscribe(
          (data: any) => {
            const response = data.body;
            if (response.responseHead.statusCode === '0000') {
              const encriptedResponse = this.util.encrypt(
                JSON.stringify(response.responseBody)
              );
              localStorage.setItem('menu_access', encriptedResponse);
              // this.route.navigate(['Dashboard']);
              window.location.reload();
            }
          },
          (err: any) => {
            console.error(err);
          }
        );
        const ur = this.util.decrypt(localStorage.getItem('ur'));
        const isOrgSuperAdmin = JSON.parse(
          localStorage.getItem('isOrgSuperAdmin')
        );
        this.isAdmin = ur === 'SA' || isOrgSuperAdmin;
        // this.userOrgList = JSON.parse(localStorage.getItem('userOrgList'));
        this.loginUserRole = localStorage.getItem('role');

        const superUserId = localStorage.getItem('superUserId');
        if (superUserId && parseInt(superUserId, 10) > 1) {
          this.isOrgAdmin = true;
        }
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  navigateTo(url: any) {
    this.route.navigate(url);
  }
  navigatetoTicket()
  {
    this.route.navigate(["/ticket"]);
  }

  handleOrgChange(e: any) {
    const orgId = e.target.value;
    const selectedOrg = this.userOrgList.filter(
      (org: any) => org.org_id === parseInt(orgId, 10)
    );
    if (selectedOrg && selectedOrg.length) {
      const payload = {
        userId: parseInt(selectedOrg[0].user_id, 10),
        orgId: parseInt(selectedOrg[0].org_id, 10),
      };
      this.loginWithOrg(payload);
    }
    this.ShowDropdown();
  }

  ShowHelpDeskDropdown() {
    this.showHelpDesk = !this.showHelpDesk;
  }

  showSettingsDropdown() {
    this.showSettings = !this.showSettings;
  }

  clickOutside() {
    this.showHelpDesk = false;
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  printInitial(): string {
    const [first, last] = (this.username || '').split(' ');
    return `${first.charAt(0).toUpperCase()}${
      last ? last.charAt(0).toUpperCase() : ''
    }`;
  }

  get showProgress(): boolean {
    return this.util.showLoader;
  }

  getMenuLabelName(menu: any) {
    // Conditional rendering of Course module label w.r.t single or multiple Course Frameworks
    if (menu.menu_name === 'Course Groups'&& this.courseFrameworkList.length===1) {
      return `${this.util.getCourseHeadingName(this.courseFrameworkList[0]?.course_framework_id)} Groups`;
    }
    if (menu.menu_name === 'Course SubGroups'&& this.courseFrameworkList.length===1) {
      return `${this.util.getCourseHeadingName(this.courseFrameworkList[0]?.course_framework_id)} SubGroups`;
    }
    //Condition for course framework based rendering of sub-menus
    if(menu.menu_name === 'Courses' && this.courseFrameworkList.length===1){
      return this.util.getCourseHeadingName(this.courseFrameworkList[0]?.course_framework_id);
    }
    return menu.menu_name;
  }
  //Function to get all the course framworks by organization id
  getCourseFrameworkList(): void {
    this.getAllService.getAll('courseFrameworksByOrgId').subscribe((data: any) => {
      this.courseFrameworkList = data.body.responseBody;
      this.changeDetectorRef.markForCheck();
    }, (err: any) => {
      this.util.errorNotification(err);
      this.changeDetectorRef.markForCheck();
    });
  }

  open(): void {
    if (this.isOpen) {
      this.notificationsService.open();
    } else {
      this.notificationsService.close();
    }
  }

  lookup() {
    this.show = !this.show;
  }
}