<div class="container">
  <!-- Create Ticket Button -->
  <div class="create-ticket-button-container">
    <button mat-raised-button color="primary" (click)="openCreateTicketModal()" class="create_ticket">
      Create Ticket
    </button>
  </div>

  <!-- Custom Modal Popup for Ticket Form -->
  <div class="vss-modal" [ngClass]="{'show': isCreatingTicket}" id="vssCreateTicketModal">
    <div class="vss-modal-dialog">
      <div class="vss-modal-content">
        <!-- Modal Header -->
        <div class="vss-modal-header">
          <h5 class="vss-modal-title">Create New Ticket</h5>
          <button type="button" class="vss-close" (click)="closeCreateTicketModal()">&times;</button>
        </div>

        <!-- Modal Body (Ticket Form) -->
        <div class="vss-modal-body">
          <form (ngSubmit)="submitTicketForm()" #ticketForm="ngForm" novalidate>
            <!-- Subject Field -->
            <div class="form-group" [ngClass]="{'has-error': ticketForm.submitted && !ticket.subject}">
              <label for="subject">Subject</label>
              <input type="text" id="subject" class="form-control" [(ngModel)]="ticket.subject" name="subject" required>
              <div *ngIf="ticketForm.submitted && !ticket.subject" class="error-message">
                Subject is required.
              </div>
            </div>
        
            <!-- Description Field -->
            <div class="form-group" [ngClass]="{'has-error': ticketForm.submitted && !ticket.description}">
              <label for="description">Description</label>
              <textarea id="description" class="form-control" [(ngModel)]="ticket.description" name="description" required></textarea>
              <div *ngIf="ticketForm.submitted && !ticket.description" class="error-message">
                Description is required.
              </div>
            </div>
        
            <!-- Priority Field -->
            <div class="form-group" [ngClass]="{'has-error': ticketForm.submitted && !ticket.priority}">
              <label for="priority">Priority</label>
              <select id="priority" class="form-control" [(ngModel)]="ticket.priority" name="priority" required>
                <option value="" disabled selected>Select Priority</option>
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
                <option value="4">Urgent</option>
              </select>
              <div *ngIf="ticketForm.submitted && !ticket.priority" class="error-message">
                Priority is required.
              </div>
            </div>
        
            <!-- Status Field -->
            <div class="form-group" [ngClass]="{'has-error': ticketForm.submitted && !ticket.status}">
              <label for="status">Status</label>
              <select id="status" class="form-control" [(ngModel)]="ticket.status" name="status" required>
                <option value="" disabled selected>Select Status</option>
                <option value="1">Open</option>
                <option value="2">Pending</option>
                <option value="3">Resolved</option>
                <option value="4">Closed</option>
              </select>
              <div *ngIf="ticketForm.submitted && !ticket.status" class="error-message">
                Status is required.
              </div>
            </div>
        
            <!-- CC Emails Field -->
            <div class="form-group" [ngClass]="{'has-error': ticketForm.submitted && !ticket.cc_emails}">
              <label for="ccEmails">CC Emails</label>
              <input type="text" id="ccEmails" class="form-control" [(ngModel)]="ticket.cc_emails" name="cc_emails">
            </div>
        
            <!-- Submit and Cancel Buttons -->
            <button 
              type="submit" 
              mat-raised-button 
              color="primary" 
              [disabled]="!ticketForm.form.valid" 
              (click)="createTicket()">
              Submit
            </button>
            <button 
              type="button" 
              mat-raised-button 
              color="warn" 
              (click)="closeCreateTicketModal()">
              Cancel
            </button>
          </form>
        </div>
        
      </div>
    </div>
  </div>

  <!-- Table Content -->
  <div *ngIf="isLoading" id="spinner">
    <mat-progress-spinner class="mat-spinner-color" mode="indeterminate"></mat-progress-spinner>
  </div>

  <custom-table
    [tableData]="tableDataSource.data"
    [tableColumns]="tableColumns"
    hideTableTitle="true"
    [moduleType]="''"
    [pageType]="''"
    (tableActionEmitter)="tableAction($event)"
    (toogleEmitter)="popupChangeStatus($event)"
    (rowAction)="rowAction($event)"
  >
  </custom-table>
</div>
