import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app/shared/services/api.service';
import { APIRequest } from '@app/shared/services/req-res.types';
import { UtilService } from '@app/shared/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

constructor(private http:HttpClient,
  private api: ApiService,
  private util:UtilService) { }

  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  /*---------------------------------------------------------------------Language related services-----------------------------------------------*/

public LanguageListData(quesid){
  return  this.api.get(
    `${URL.CONTENT.LANGUAGE_LIST}/${quesid}`, {});
}

public getLanguage(){
  return  this.api.get(
    `${URL.CONTENT.LANGUAGE}`, {});
}

public saveLanguage(payload){
    return this.api.post(`${URL.CONTENT.LANGUAGE_LIST}`, payload);
}

public deleteLanguage(id:string){
  return this.api.delete(URL.CONTENT.LANGUAGE_LIST,id);
}

/*--------------------------------------------------------Test related services--------------------------------------------------------*/
public QuestionPaperListData(data){
  const postData: APIRequest = {
       
    requestBody: {
      ...data,
      orgId: parseInt(this.orgID, 10),
    }
  };
  
  return this.api.post(URL.CONTENT.GET_QUESTION_PAPER_LIST, postData );
}
deleteQuestionPaper(id:any){
  return this.api.delete(URL.CONTENT.DELETE_QUESTION_PAPER, id)
}

public UpdateQuestionPaperStatus(data){
  const postData: APIRequest = {
       
    requestBody: {
      ...data,
    }
  };
  
  return this.api.post(URL.CONTENT.UPDATE_QUESTION_PAPER_STATUS, postData );
}

public getQuestionPaperVersion(payload){
  const postData: APIRequest = {
       
    requestBody: {
      ...payload,
      orgId: parseInt(this.orgID, 10),
    }
  };
  return this.api.post(`${URL.CONTENT.GET_QUESTION_PAPER_VERSION_LIST}`, postData);
}

public addEditQuestionPaperVersion(payload){
  const postData: APIRequest = {   
    requestBody: {
      ...payload,
      orgId: parseInt(this.orgID, 10),
    }
  };
  return this.api.post(`${URL.CONTENT.ADD_EDIT_QUESTION_PAPER_VERSION}`, postData);
}

public deleteQuestionPaperVersion(payload){
  const postData: APIRequest = {   
    requestBody: {
      ...payload,
    }
  };
 return this.api.post(`${URL.CONTENT.DELETE_QUESTION_PAPER_VERSION}`,postData );
}

  public updateStatusQuestionPaperVersion(payload){
    const postData: APIRequest = {   
      requestBody: {
        ...payload,
      }
    };
    return this.api.post(`${URL.CONTENT.UPDATE_STATUS_QUESTION_PAPER_VERSION}`, postData);
  }
  /*----------------------------------------------------CANDIDATE RELATED SERVICES-------------------*/
  public updateCandidateStatus(payload) {
   
    const postData: APIRequest = {   
      requestBody: payload
    };
  
    // Send the updated object to the backend
    return this.api.put(`${URL.OPERATIONS.CANDIDATES}/${payload.id}`, postData);
  }
  
}