import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css']
})
export class LinechartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() chartData: { date: string, count: number }[] = []; // Input property for chart data
  @Input() graphTitle: string = "Leads Count Over Time"; // Input property for dynamic title

  public chartOptions: Partial<ChartOptions>;

  constructor(private cdr: ChangeDetectorRef) {
    this.chartOptions = {
      series: [
        {
          name: "Leads Count",
          data: [] // Initially empty, will be updated with data
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: this.graphTitle, // Initialize with default or passed title
        align: "center"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on rows
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [], // Initially empty, will be filled with all dates
        tickAmount: 5 // Show intervals of approximately 4 points on the x-axis
      }
    };
  }

  ngOnInit(): void {
    // This ensures chart data is updated on component initialization
    this.updateChartData(this.chartData);
    this.updateChartTitle(this.graphTitle);
  }

  ngAfterViewInit(): void {
    // Ensure chart is rendered once the view is initialized
    if (this.chart) {
      this.updateChartData(this.chartData);
      this.updateChartTitle(this.graphTitle);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData && !changes.chartData.firstChange) {
      this.updateChartData(this.chartData);
    }
    if (changes.graphTitle && !changes.graphTitle.firstChange) {
      this.updateChartTitle(this.graphTitle);
    }
  }

  /**
   * Method to update the chart with all data and interval-based labels
   * @param data - Original data array
   */
  updateChartData(data: { date: string, count: number }[]) {
    const labels = data.map(d => new Date(d.date).toLocaleDateString()); // Format the date
    const values = data.map(d => d.count);
  
    // Ensure proper update of chartOptions and trigger change detection
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: `${this.graphTitle} Count`,
          data: values
        }
      ],
      xaxis: {
        categories: labels, // Use all dates as categories
        tickAmount: 3 // Show approximately 4 evenly distributed labels
      }
    };

    const totalCount = this.chartData.reduce((sum, dataPoint) => sum + dataPoint.count, 0) || 0;
    const formattedTotalCount = ['Bills', 'Invoice', 'Collection'].includes(this.graphTitle) ? `₹${totalCount}` : totalCount; // Add rupee symbol for Finance
    const newTitle = `${this.graphTitle} (Total: ${formattedTotalCount})`; 
    if (this.chartOptions.title) {
      this.chartOptions.title.text = newTitle;
      this.cdr.detectChanges(); // Trigger change detection
    }
    // Manually trigger change detection after data update
    this.cdr.detectChanges();
  }

  /**
   * Method to update the chart title dynamically
   * @param title - New title for the graph
   */
  updateChartTitle(title: string) {
    const totalCount = this.chartData.reduce((sum, dataPoint) => sum + dataPoint.count, 0) || 0;
    const formattedTotalCount = ['Bills', 'Invoice', 'Collection'].includes(title) ? `₹${totalCount}` : totalCount; // Add rupee symbol for Finance
    const newTitle = `${title} (Total: ${formattedTotalCount})`; 
    if (this.chartOptions.title) {
      this.chartOptions.title.text = newTitle;
      this.cdr.detectChanges(); // Trigger change detection
    }
  }
  
}
