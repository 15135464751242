<div *ngIf="isLoading" id="spinner">
  <mat-progress-spinner
    class="mat-spinner-color"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
<div class="dashboard">
  <!-- Date Range Buttons -->
  <div class="date-range">
    <button
      *ngFor="let range of dateRanges"
      [ngClass]="{ active: selectedRange === range }"
      (click)="selectRange(range)"
    >
      {{ range }}
    </button>
  </div>

  <!-- Date Picker Range (if 'Custom' is selected) -->
  <div *ngIf="selectedRange === 'Custom'" class="datepicker-range">
    <mat-form-field appearance="fill">
      <mat-label>Start Date</mat-label>
      <input
        matInput
        [matDatepicker]="startPicker"
        [(ngModel)]="startDate"
        (ngModelChange)="onDateChange()"
        placeholder="Choose start date"
      />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>End Date</mat-label>
      <input
        matInput
        [matDatepicker]="endPicker"
        [(ngModel)]="endDate"
        (ngModelChange)="onDateChange()"
        placeholder="Choose end date"
      />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <!-- Accordion Sections -->
  <div class="accordion">
    <div class="accordion-item" *ngFor="let section of sections; let i = index">
      <div class="accordion-header" (click)="toggleSection(i)">
        {{ section.title }}
        <span class="toggle-icon">{{ openSection === i ? '▼' : '▶' }}</span>
      </div>
      <div class="accordion-content" *ngIf="openSection === i">
        <!-- Sales Section -->
        <div *ngIf="section.title === 'Sales'">
          <app-linechart [chartData]="leadsData" [graphTitle]="'Leads'"></app-linechart>
          <app-linechart [chartData]="customersData" [graphTitle]="'Customers'"></app-linechart>
          <app-newbarchart  [chartTitle]="''" [chartSeries]="leadCustomerData" [chartCategories]="['']"></app-newbarchart>
        </div>

        <!-- Content Section -->
        <div *ngIf="section.title === 'Content'">
          <app-linechart [chartData]="coursesData" [graphTitle]="'Courses'"></app-linechart>
          <app-linechart [chartData]="questionBanksData" [graphTitle]="'Question Banks'"></app-linechart>
          <app-newbarchart  [chartTitle]="''" [chartSeries]="questionsData" [chartCategories]="['Easy', 'Medium', 'Difficult']"></app-newbarchart>
        </div>

        <!-- Operations Section -->
        <div *ngIf="section.title === 'Operations'">
          <app-linechart [chartData]="candidatesData" [graphTitle]="'Candidates'"></app-linechart>
          <app-linechart [chartData]="assessmentsData" [graphTitle]="'Assessments'"></app-linechart>
          <app-newbarchart  [chartTitle]="'Violations'" [chartSeries]="violationsData" [chartCategories]="['Violations']"></app-newbarchart>
        </div>

        <!-- Finance Section -->
        <div *ngIf="section.title === 'Finance'">
          <app-linechart [chartData]="billsData" [graphTitle]="'Bills'"></app-linechart>
          <app-linechart [chartData]="invoicesData" [graphTitle]="'Invoice'"></app-linechart>
          <app-linechart [chartData]="collectedData" [graphTitle]="'Collection'"></app-linechart>
        </div>

        <!-- Resources Section -->
        <div *ngIf="section.title === 'Resources'">
          <app-linechart [chartData]="rolesData" [graphTitle]="'Roles'"></app-linechart>
          <app-linechart [chartData]="categoriesData" [graphTitle]="'Categories'"></app-linechart>
          <app-linechart [chartData]="usersData" [graphTitle]="'Users'"></app-linechart>
        </div>

        <!-- Activity Tracker Section -->
        <div *ngIf="section.title === 'Activity Tracker'">
          <div class="container">
            <!-- Custom Tab Set -->
            <div class="tabs">
              <div
                class="tab-title"
                *ngFor="let tab of tabs"
                [ngClass]="{ active: selectedTab === tab }"
                (click)="selectTab(tab)"
              >
                {{ tab }}
              </div>
            </div>

            <!-- Tab Content -->
            <div class="tab-content" *ngIf="selectedTab === 'Org Updates'">
              <custom-table
                [tableData]="orgUpdatesData"
                [tableColumns]="displayedColumns"
                [tableActions]="tableActions"
                (tableActionEmitter)="handleTableAction($event)"
                (rowAction)="rowAction($event)"
                hideTableTitle="true"
                [filterAttributes]="filterAttributes"
                [dateRangeDetails]="dateRangeDetails"
                (toogleEmitter)="updateStatus($event)"
              ></custom-table>
            </div>

            <div class="tab-content"*ngIf="selectedTab === 'My Updates'">
              <custom-table
                [tableData]="myUpdatesData"
                [tableColumns]="displayedColumns"
                [tableActions]="tableActions"
                (tableActionEmitter)="handleTableAction($event)"
                (rowAction)="rowAction($event)"
                hideTableTitle="true"
                [filterAttributes]="filterAttributes"
                [dateRangeDetails]="dateRangeDetails"
                (toogleEmitter)="updateStatus($event)"
              ></custom-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
